import React, { useState } from "react";
import {Link, navigate} from "gatsby"
import authHeader from "../utils/auth-header";
import toast, { Toaster } from 'react-hot-toast';
import {StaticImage} from "gatsby-plugin-image";
import LoadingSpinner from "./loading-spinner";


const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
  const handleLogin =  (e) => {

    e.preventDefault();

      try {


          setSubmitDisabled(true);
          setLoading(true);

          const requestOptions = {
              method: 'POST',
              headers: authHeader(),
              body: JSON.stringify({
                  email: email,
                  password: password,

              })
          };

          fetch(process.env.WASHCAR_API_URL + "/auth/partners-login", requestOptions)
              .then(response => response.json()) // parse JSON from request
              .then(resultData => {

                  setSubmitDisabled(false);
                  setLoading(false);

                  if(resultData.status === 'Error'){
                      toast.error(resultData.alert.text)
                  }
                  else{

                      if (resultData.access_token) {
                          const userData = {accessToken:resultData.access_token};
                          localStorage.setItem("user", JSON.stringify(userData));


                            //@todo to seperate function
                          const requestOptions = {
                              method: 'GET',
                              headers: authHeader(),
                          };

                          fetch(process.env.WASHCAR_API_URL + "/partners/current-user-info", requestOptions)
                              .then(response => response.json()) // parse JSON from request
                              .then(resultData => {
                                  localStorage.setItem("userData", JSON.stringify(resultData));
                              })

                          return navigate("/dashboard")
                      }
                      else{
                          alert('error');
                      }



                      }







              }) // set data for the number of stars

      } catch (err) {
          console.log(err);
      }


  };

  return (
      <div>

          <Toaster
              position="top-center"
              reverseOrder={false}
          />


          {loading ? <div className="pos-login"><LoadingSpinner /></div> : null}

          <form onSubmit={handleLogin} className={'box'}  >

         <div className={'has-text-centered'}>
             <StaticImage
                 src="../images/logo.png"
                 alt="Logo"
                 formats={["auto"]}
                 style={{marginBottom: `1.45rem`, maxWidth: '250px',marginTop: `1.45rem`}}
             />

             <h1  style={{marginBottom: `1.45rem`, fontSize: '60px', fontWeight: 'bold', color: '#351d66'}}>Partners</h1>

         </div>


            <div className="field">
                <label className="label">E-pasts</label>
                <div className="control">
                    <input required className="input" type="text"   value={email}   onChange={(e) => setEmail(e.target.value)}/>
                </div>
            </div>

            <div className="field">
                <label className="label">Parole</label>
                <div className="control">
                    <input required className="input" type="password"     value={password}        onChange={(e) => setPassword(e.target.value)}/>
                </div>
            </div>


            <div className="field is-grouped">
                <div className="control">
                    <button disabled={submitDisabled} type="submit" className="button is-link">Pieslēgties</button>
                </div>
            </div>

              <Link  activeClassName="is-active" to="/forgot-password">Aizmirsāt paroli?</Link>

        </form>
      </div>
  );
};

export default Login;
